import React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

import { RECAPTCHA_SITE_KEY } from "@config/config"
import { css, Global } from "@emotion/react"

const globalCss = css`
    .grecaptcha-badge {
        display: none;
    }
`

interface RecaptchaProviderProps extends React.PropsWithChildren<{}> {}

const RecaptchaProvider: React.FC<RecaptchaProviderProps> = ({ children }: RecaptchaProviderProps) => (
    <>
        <Global styles={globalCss} />
        <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY as string}>{children}</GoogleReCaptchaProvider>
    </>
)

export default RecaptchaProvider
