import { serverSideTranslations } from "next-i18next/serverSideTranslations"

import Login from "@app/Login"
import { defaultLanguage } from "@config/i18n"
import { withNonAuthClient } from "@hoc/withNonAuth"

export const getStaticProps = async ({ locale }: { locale: string }) => ({
    props: {
        ...(await serverSideTranslations(locale || defaultLanguage, ["components", "pages", "common", "login"]))
    }
})

export default withNonAuthClient(Login)
