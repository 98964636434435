import { useEffect, useState } from "react"

import getDeviceId from "@helpers/device-id"

const useDeviceId = () => {
    const [deviceId, setDeviceId] = useState<string | undefined>(undefined)
    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(() => {
        const handleDeviceId = async () => {
            const newDeviceId = await getDeviceId()
            setDeviceId(newDeviceId)
            setIsLoading(false)
        }

        handleDeviceId()
    }, [])

    return { deviceId, isLoading }
}

export default useDeviceId
