import RecaptchaProvider from "@components/v2/Recaptcha"
import useAuth from "@hooks/useAuth"
import Login from "./Login"

const RecaptchaWrapper = () => {
    const { auth } = useAuth()

    if (auth.isLoggedIn) {
        return <Login />
    }

    return (
        <RecaptchaProvider>
            <Login />
        </RecaptchaProvider>
    )
}

export default RecaptchaWrapper
