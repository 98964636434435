import { useEffect, useRef, useState } from "react"
import { useTranslation } from "next-i18next"
import Form, { Field } from "rc-field-form"
import { useMediaQuery } from "react-responsive"

import ConditionalWrapper from "@components/ConditionalWrapper"
import { When } from "@components/If"
import Link from "@components/Link"
import { Button } from "@components/v3/Button"
import Icons from "@components/v3/Icons"
import InputVerification from "@components/v3/Input/InputVerification"
import Modal from "@components/v3/Modal"
import { LOST_GOOGLE_AUTH } from "@const/moengage-analytic-event"
import useAuth, { useLogin, useOpen2fa } from "@hooks/useAuth"
import useGlobalLoading from "@hooks/useGlobalLoading"
import Space from "@layouts/Space"

const forceBlurInput = (element: HTMLInputElement) => {
    element.blur()
}

const ModalLogin2fa: React.FC = () => {
    const { t } = useTranslation("login")
    const { auth } = useAuth()
    const { login2fa } = useLogin()
    const { loading } = useGlobalLoading()
    const { isOpen2fa, close2fa } = useOpen2fa()
    const isDesktop = useMediaQuery({ minWidth: 1280 })

    const [form] = Form.useForm()
    const otpValue = Form.useWatch("otp", form)
    const [error, setError] = useState("")
    const otpRef = useRef() as React.MutableRefObject<HTMLDivElement>

    const handleCloseModal = () => {
        close2fa()
    }

    const handleSubmit = async (values: { otp: string }) => {
        loading.start()

        const formData: Login2faPayload = {
            hash: auth.hash as string,
            otp: values?.otp
        }

        const res = await login2fa(formData)

        if (res?.error) {
            setError(res?.error?.message)
        } else {
            handleCloseModal()
        }

        if (otpRef.current) {
            const lastInputElement = otpRef.current.querySelectorAll("input")[5]
            forceBlurInput(lastInputElement)
        }

        loading.stop()
    }

    useEffect(() => {
        setError("")
        form.resetFields()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen2fa])

    return (
        <Modal
            open={isOpen2fa}
            withCloseButton={isDesktop}
            disableBackdropClick
            header={
                <ConditionalWrapper
                    condition={!isDesktop}
                    // eslint-disable-next-line react/no-unstable-nested-components
                    wrapper={(children) => (
                        <Space align='center' className='!gap-3'>
                            <Icons
                                icon='XClose'
                                className='w-6 h-6 text-icon-contrast dark:text-dark-icon-contrast cursor-pointer'
                                onClick={handleCloseModal}
                            />

                            {children}
                        </Space>
                    )}
                >
                    <h1 className='block xl:text-center title-3 xl:title-2 text-main dark:text-dark-main'>
                        {t("2fa.title")}
                    </h1>
                </ConditionalWrapper>
            }
            modalHeaderClassName='!px-4 xl:!px-6'
            onClose={handleCloseModal}
            fullScreen={!isDesktop}
        >
            <Form form={form} onFinish={handleSubmit}>
                <Space direction='vertical' size='middle' className='!flex px-4 py-6 xl:p-6'>
                    <Field name='otp'>
                        {({ onChange }) => (
                            <InputVerification
                                groupRef={otpRef}
                                type='authenticator'
                                size='md'
                                block
                                error={error}
                                shouldAutoFocus
                                onChange={(value) => {
                                    setError("")
                                    onChange(value)
                                }}
                                onFinish={(value) => handleSubmit({ otp: value })}
                            />
                        )}
                    </Field>
                    <Link
                        href='/recovery-auth'
                        className='text-action-secondary dark:text-dark-action-secondary button-2 mx-auto'
                        onClick={async () => {
                            const { default: moengageAnalytic } = await import("@lib/moengage-analytic")
                            moengageAnalytic.track(LOST_GOOGLE_AUTH, {
                                source: "Google auth verification"
                            })
                        }}
                    >
                        {t("2fa.recovery_link_label")}
                    </Link>
                    <When condition={isDesktop}>
                        <Button type='submit' className='mt-2' block disabled={otpValue?.length !== 6 || !!error}>
                            {t("2fa.next")}
                        </Button>
                    </When>
                </Space>
            </Form>
        </Modal>
    )
}

export default ModalLogin2fa
