/* eslint-disable simple-import-sort/imports */
import { useEffect, useState } from "react"
import { useTranslation } from "next-i18next"
import tw, { styled } from "twin.macro"
import Form, { Field } from "rc-field-form"
import { css } from "@emotion/css"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import classNames from "classnames"

import { EMAIL_LOGIN_REGEX } from "@const/regex"
import {
    LOGIN_EMAIL_CONFIRMATION,
    LOGIN_EMAIL_SEND,
    LOGIN_RESET_PASSWORD,
    LOGIN_SUSPICIOUS,
    WRONG_EMAIL_PASSWORD
} from "@const/errno"
import { FORGET_PASSWORD } from "@const/moengage-analytic-event"

import useAuth, {
    // useGoogleUri,
    useLogin,
    useLoginCallback
} from "@hooks/useAuth"
import useSettings from "@hooks/useSettings"
import useError from "@hooks/useError"
import useGlobalLoading from "@hooks/useGlobalLoading"
import Container from "@components/v2/Container"
import Input from "@components/v3/Input"
import InputPassword from "@components/v3/Input/InputPassword"
import { Button } from "@components/v3/Button"
import Link from "@components/Link"
import { When } from "@components/If"
import Head from "@components/Head"
// import AppleLoginButton from "@components/AppleLoginButton"

import OpenInAppSheet from "@components/OpenInAppSheet"
import { useRouter } from "next/router"
import { useMediaQuery } from "react-responsive"
import useDeviceId from "@hooks/useDeviceId"
// import GoogleIcon from "./svg/google.svg"
import ModalLogin2fa from "./components/ModalLogin2fa"

const LoginWrapper = tw.section`
    py-4 xl:pt-12 xl:pb-20 
    bg-background dark:bg-dark-background
`

const LoginBox = tw.div`
    flex flex-col items-center gap-6 xl:gap-8 
    mx-auto xl:p-8
    xl:max-w-[457px]
    xl:border border-main dark:border-dark-main
    xl:rounded-3xl 
`

const Footer = styled.div`
    ${tw`
        flex items-center justify-center mt-[90px] xl:mt-8 gap-1
        py-4
        paragraph-2 text-additional dark:text-dark-additional
    `}

    a {
        ${tw`m-0 text-primary dark:text-dark-primary button-1`}
    }
`

const Label = tw.label`
    title-4 text-main dark:text-dark-main
`

const Space = tw.div`
    flex flex-col gap-4
`

const ActionSpace = tw(Space)`gap-6 xl:gap-8`

const StyledLink = tw(Link)`
    text-primary text-right
    mt-4 mb-8 self-end
    button-2 capitalize
`

const Error = tw.span`
    text-error dark:text-dark-error pt-1 paragraph-4
`

// const Divider = tw.div`
//     flex items-center text-center gap-3 my-2 paragraph-4 text-additional dark:text-dark-additional uppercase
// `

const groupCss = css`
    margin-bottom: 0 !important;
`

interface LoginForm {
    email: string
    password: string
}

const Login = () => {
    const router = useRouter()
    const { t } = useTranslation()
    const { login } = useLogin()
    const { auth } = useAuth()
    // const { google, isLoading: isGoogleUriLoading } = useGoogleUri()
    const { settings, isLoading: isSettingsLoading } = useSettings()
    const { executeRecaptcha } = useGoogleReCaptcha()
    const {
        error: { code: errorCode },
        resetError
    } = useError()
    const { loading } = useGlobalLoading()
    const { deviceId, isLoading: deviceIdLoading } = useDeviceId()

    const { handleLoginCallback } = useLoginCallback()

    const [error, setError] = useState<string>("")
    const isMobile = useMediaQuery({ maxWidth: 1279 })

    const isLoading = isSettingsLoading || deviceIdLoading

    const { code } = router.query

    useEffect(() => {
        if (isLoading) {
            loading.start()
        } else {
            loading.stop()
        }

        return () => {
            loading.stop()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading])

    const handleSubmit = async (values: LoginForm) => {
        loading.start()
        const { email, password } = values

        const recaptcha = settings?.recaptcha && (await executeRecaptcha?.("/login"))

        const formData: LoginPayload = {
            email,
            password,
            c: recaptcha || ""
        }

        const res = await login(formData)

        if (res?.error) {
            setError(res?.error?.message)
        } else {
            resetError()
        }

        loading.stop()
    }

    const fieldChanges = () => {
        if (error) {
            setError("")
        }
    }

    const isLoginErrorValidation =
        errorCode === LOGIN_EMAIL_CONFIRMATION ||
        errorCode === LOGIN_EMAIL_SEND ||
        errorCode === LOGIN_SUSPICIOUS ||
        errorCode === LOGIN_RESET_PASSWORD

    const onLoginCallback = () => handleLoginCallback({ code: code as string })

    useEffect(() => {
        if (code && !isMobile && !auth.isLoggedIn) {
            if (deviceId) {
                onLoginCallback()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code, isMobile, auth, deviceId])

    return (
        <>
            <Head title={t("common:sign_in")} />

            <LoginWrapper className='reku-new'>
                <Container>
                    <LoginBox>
                        <h1 className='heading-6 xl:heading-5 text-bold dark:text-dark-bold'>{t("common:sign_in")}</h1>
                        <Form onFinish={handleSubmit} onFieldsChange={fieldChanges} className='w-full flex flex-col'>
                            {(_, forms) => {
                                const emailError = forms.getFieldError("email")[0]
                                const passwordError = forms.getFieldError("password")[0]

                                return (
                                    <>
                                        <Space>
                                            <Field
                                                name='email'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("common:validation.required")
                                                    },
                                                    {
                                                        pattern: EMAIL_LOGIN_REGEX,
                                                        message: t("common:validation.invalid_email")
                                                    }
                                                ]}
                                            >
                                                <Input
                                                    size='md'
                                                    className={
                                                        isLoginErrorValidation || errorCode === WRONG_EMAIL_PASSWORD
                                                            ? "!border-error dark:!border-dark-error"
                                                            : ""
                                                    }
                                                    groupClassName={groupCss}
                                                    label={<Label>{t("login:email.label")}</Label>}
                                                    placeholder={t("login:email.placeholder")}
                                                    error={emailError}
                                                    errorClassName='!paragraph-4 !font-normal'
                                                />
                                            </Field>
                                            <Field
                                                name='password'
                                                rules={[{ required: true, message: t("login:password.required") }]}
                                            >
                                                <InputPassword
                                                    error={passwordError}
                                                    autoComplete='new-password'
                                                    label={<Label>{t("login:password.label")}</Label>}
                                                    placeholder={t("login:password.placeholder")}
                                                    groupClassName={groupCss}
                                                    className={classNames({
                                                        "!border-error dark:!border-dark-error":
                                                            isLoginErrorValidation || errorCode === WRONG_EMAIL_PASSWORD
                                                    })}
                                                    errorClassName='!paragraph-4 !font-normal'
                                                />
                                            </Field>
                                        </Space>
                                        <When condition={error}>
                                            <Error>{!isLoginErrorValidation ? error : null}</Error>
                                        </When>
                                        <StyledLink
                                            href='/forgot-password'
                                            onClick={async () => {
                                                const { default: moengageAnalytic } = await import(
                                                    "@lib/moengage-analytic"
                                                )
                                                moengageAnalytic.track(FORGET_PASSWORD)
                                            }}
                                        >
                                            {t("login:password.forget")}
                                        </StyledLink>

                                        <ActionSpace>
                                            <Button type='submit' block tw='button-1'>
                                                {t("login:sign_in")}
                                            </Button>
                                            {/* <Divider className="before:content-[''] after:content-[''] before:relative after:relative before:w-1/2 after:w-1/2 before:border-t after:border-t  before:border-main after:border-main dark:before:border-dark-main dark:after:border-dark-main">
                                                {t("common:or")}
                                            </Divider>
                                            <Space>
                                                <Link href={google?.uri || ""}>
                                                    <Button
                                                        variant='gray'
                                                        type='button'
                                                        block
                                                        outline
                                                        tw='button-1 gap-[6px]'
                                                    >
                                                        <GoogleIcon width={20} height={20} />{" "}
                                                        {t("login:sign_in_with_google")}
                                                    </Button>
                                                </Link>
                                                <AppleLoginButton>{t("login:sign_in_with_apple")}</AppleLoginButton>
                                            </Space> */}
                                        </ActionSpace>
                                        <Footer>
                                            {t("login:dont_have_account")}
                                            <StyledLink href='/uss/register'>{t("login:sign_up")}</StyledLink>
                                        </Footer>
                                    </>
                                )
                            }}
                        </Form>
                    </LoginBox>
                </Container>
            </LoginWrapper>

            <ModalLogin2fa />
            <When condition={isMobile && code}>
                <OpenInAppSheet code={code as string} onCallback={onLoginCallback} />
            </When>
        </>
    )
}

export default Login
