import React, { useState } from "react"
import Image from "next/image"
import { useTranslation } from "next-i18next"
import { isChrome, isChromium, isSafari } from "react-device-detect"
import tw from "twin.macro"

import { Case, Default, Switch } from "@components/If"
import Link from "@components/Link"
import BottomSheet from "@components/v2/BottomSheet"
import { Button } from "@components/v3/Button"
import { css, Global } from "@emotion/react"
import Space from "@layouts/Space"

import Browser from "./images/Browser.svg"
import Chrome from "./images/Chrome.svg"
import RekuIcon from "./images/reku.png"
import Safari from "./images/Safari.svg"

const BrowserText = tw.span`
    title-4
`

interface Props {
    code: string
    onCallback: () => void
}

const OpenInAppSheet: React.FC<Props> = ({ code, onCallback }) => {
    const { t } = useTranslation("common")
    const [open, setOpen] = useState(true)

    let browserType

    if (isChrome) {
        browserType = "Chrome"
    } else if (isSafari) {
        browserType = "Safari"
    } else {
        browserType = "Browser"
    }

    const handleClose = () => {
        setOpen(false)
        onCallback?.()
    }

    return (
        <>
            <Global
                styles={css`
                    [data-rsbs-overlay] {
                        z-index: 2147483640 !important;
                    }
                `}
            />
            <BottomSheet
                open={open}
                onDismiss={handleClose}
                header={
                    <Space className='!py-3'>
                        <span className='title-3'>{t("open_in")}</span>
                    </Space>
                }
            >
                <Space className='px-4 pb-4 !gap-6 !w-full' direction='vertical'>
                    <Space direction='horizontal' justify='between' align='center' className='!w-full'>
                        <Space align='center'>
                            <Image src={RekuIcon.src} width={32} height={32} />
                            <Space direction='vertical' align='start' className='!gap-0'>
                                <BrowserText>Reku:</BrowserText>
                                <BrowserText>Trade & Investasi Crypto</BrowserText>
                            </Space>
                        </Space>
                        <Link
                            href={`https://reku.onelink.me/5mL7?af_web_dp=https%3A%2F%2Freku.id%2Flogin&af_xp=custom&pid=login_improvement&deep_link_value=%2Flogin&code=${code}`}
                            onClick={() => setOpen(!open)}
                        >
                            <Button size='md' width={100}>
                                {t("open")}
                            </Button>
                        </Link>
                    </Space>
                    <Space direction='horizontal' justify='between' align='center' className='!w-full'>
                        <Space align='center'>
                            <Switch>
                                <Case condition={isChrome || isChromium}>
                                    <Chrome className='!w-8 !h-8' />
                                </Case>
                                <Case condition={isSafari}>
                                    <Safari className='!w-8 !h-8' />
                                </Case>
                                <Default>
                                    <Browser className='!w-8 !h-8' />
                                </Default>
                            </Switch>

                            <BrowserText>{browserType}</BrowserText>
                        </Space>
                        <Button size='md' width={100} outline onClick={handleClose}>
                            {t("continue")}
                        </Button>
                    </Space>
                </Space>
            </BottomSheet>
        </>
    )
}

export default OpenInAppSheet
